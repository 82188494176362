<template>
  <div>
    <el-select style="width: 100%" @change="selectStaByYear" v-model="year" placeholder="请选择发证年份">
      <el-option
          v-for="item in years"
          :key="item"
          :label="item"
          :value="item">
      </el-option>
    </el-select>
    <div style="margin-top: 40px">
      <div style="width: 50%; height: 100%; display: inline-block; float: left;">
        <el-table
            :data="certCountByOrg"
            stripe
            style="width: 90%">
          <el-table-column
              prop="org"
              label="市州"
              width="150">
          </el-table-column>
          <el-table-column
              prop="jzg"
              label="架子工数"
              width="150">
          </el-table-column>
          <el-table-column
              prop="qzg"
              label="起重工数"
              width="150">
          </el-table-column>
          <el-table-column
              prop="小计"
              label="小计">
          </el-table-column>
        </el-table>
      </div>
      <div style="width: 50%; height: 100%; display: inline-block; margin-left: 0; float: left;">
        <el-table
            :data="certCountByMonth"
            stripe
            style="width: 90%; float: right">
          <el-table-column
              prop="yue"
              label="月份"
              width="150">
          </el-table-column>
          <el-table-column
              prop="jzg"
              label="架子工数"
              width="150">
          </el-table-column>
          <el-table-column
              prop="qzg"
              label="起重工数">
          </el-table-column>
          <el-table-column
              prop="小计"
              label="小计"
              width="150">
          </el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>

<script>
import request from "@/util/request";

export default {
  name: "Index",
  data() {
    return {
      year: "",
      certCountByOrg: [],
      certCountByMonth:[],
      years:[]
    }
  },
  methods: {
    selectStaByYear() {
      let message = this.$message;
      request({
        url: "/manager/getCertSta.do",
        method: "GET",
        params: {
          year: this.year
        }
      }, res => {
        if (res.code === 200) {
          this.certCountByMonth = res.data.reviewCountByMonth;
          this.certCountByOrg = res.data.reviewCountByOrg;
          message.success("查询成功");
        }
      }, err => {
      }, this.$store.getters.token());
    }
  },created() {
    request({
      url: "/manager/getCertSta.do",
      method: "GET",
    }, res => {
      if (res.code === 200) {
        this.certCountByMonth = res.data.reviewCountByMonth;
        this.certCountByOrg = res.data.reviewCountByOrg;
        this.years = res.data.years;
        this.year = this.years[this.years.length -1]
      }
    }, err => {
    }, this.$store.getters.token());
  }
}
</script>

<style>

</style>